import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import Revenue from "../assets/svg/revenue.svg"
import Office from "../assets/svg/office.svg"
import Infrastructure from "../assets/svg/infrastructure.svg"
import { StaticImage } from "gatsby-plugin-image"
import image from "../assets/images/image1.png"
import internet from "../assets/images/connectedworld.png"
const Index = () => {
  return(
    <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-white">
            HealthCare BackOffice Solutions
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light text-white">
            Our vision is to streamline the operational workflows, increase efficiency and optimize
            the goals of our clients and help them concentrate on their core business practices.
          </p>
          {/* <p className="mt-8 text-white md:mt-12">
            <Button size="lg">Get Started</Button>
          </p> */}
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold text-white">Back Office Services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800">
              <p className="font-semibold text-md text-white">PRACTICE MANAGEMENT SERVICES</p>
              <p className="mt-4 text-white">
                Full Revenue Cycle Management, Human Resources/Staff Management, Payroll &
                Bookkeeping, Staff Development and Training.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800">
              <p className="font-semibold text-md text-white">BILLING & CODING</p>
              <p className="mt-4 text-white">
                Our expert professional staff are trained and licensed to manage all claims in an
                efficient and timely manner. it accelerates your revenue.
              </p>
              <br></br>
              <br></br>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800 ">
              <p className="font-semibold text-white text-md">CONSULTANCY SERVICES</p>
              <p className="mt-4 text-white">
                Empowering practices with IT solutions and comprehensive tools, dashboards for
                communications patient to clinical staff communications.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800 ">
              <p className="font-semibold text-white text-md">PHONE ANSWERING</p>
              <p className="mt-4 text-white">
                Patients Become More Satisfied, Doctor’s office is more focused on patients
                encounters, Follow-ups and Appointment Reminders.
              </p>
              <br></br>
              <br></br>
            </Card>
          </div>
        </div>
      </div>
    </section>
    {/* IT Services */}
    <section id="features" className="py-20 lg:pb-40">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold text-white">IT Services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8  hover:shadow-sm hover:bg-blue-800">
              <p className="font-semibold text-md text-white">WEB DEVELOPMNET</p>
              <p className="mt-4 text-white">
                Create a landing page for people to find you on the internet. This process includes
                web hosting, a domain name, attractive design, complete doctor’s profile and
                specialties, and appointment management.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8  hover:shadow-inner hover:bg-blue-800">
              <p className="font-semibold text-md text-white">TRAFFIC GENERATION</p>
              <p className="mt-4 text-white">
                Our dedicated professionals will work constantly to make sure people who search for
                you, actually find you. Being easier to find on the web for your professional
                expertise delivers more patients to your office.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800">
              <p className="font-semibold text-white text-md">DIGITAL MARKETING</p>
              <p className="mt-4 text-white">
                Digital marketing services provide businesses with an opportunity to market their
                brand 24/7 at a low cost. We ensure in generating a bigger return on investment, on
                any budget you are comfortable in spending.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 hover:shadow-sm hover:bg-blue-800">
              <p className="font-semibold text-white text-md">OTHER SERVICES</p>
              <p className="mt-4 text-white">
                PBX services, HIPAA Compliance, Network & Securtiy, Firewalls, Cloud Services, IT Consultancy, Document Management, Electronic Health/Medical Records.
              </p>
              <br></br>

            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold text-white leading-tight">
            REVENUE CYCLE MANAGEMENT
          </h3>
          <p className="mt-8 text-white text-xl font-light leading-relaxed">
            WMO is uniquely placed to offer revenue cycle management (Billing) and healthcare
            backoffice services with its experienced staff, with its proficiency in the technology,
            management and passion for servicing its clients. We realize that revenue cycle
            management is a task where clients hand over the responsibility of their entire revenues
            to us. The weight of this responsibility needs to be carried with the heavy lifting that
            it warrants, with utmost care, timeliness, proficiency and care. Once we embark on this
            mission with any new client, we make sure there are no exceptions in our path to reach
            the assigned goals.
          </p>
        </div>
      }
      secondarySlot={<StaticImage 
        style={{
          zIndex: -100,       
        }}
      className='z-50'
      src="../assets/svg/revenue.svg" 
      alt="revenue cycle management" 
      layout='fullWidth'
      loading='eager'
      placeholder='revealed'
       />}    
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold text-white leading-tight">
            24/7 BACKOFFICE SUPPORT
          </h3>
          <p className="mt-8 text-white text-xl font-light leading-relaxed">
            Businesses, especially healthcare facilities, work round the clock. They often have
            employees working at different geographical locations. Want to have a workforce that
            takes care of your business 24/7? You will find in us the resource that will enable your
            business to run 24x7x365. Get your tasks worked on 24/7 and create efficiency in your
            workflow.
          </p>
        </div>
      }
      secondarySlot={<StaticImage 
        style={{
          zIndex: -100,       
        }}
        src="../assets/svg/office.svg"
        alt="office support"
        layout='fullWidth'
        loading='eager'
        placeholder='office'
        />}  
    />

    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold text-white leading-tight">INFRASTRUCTURE</h3>
          <p className="mt-8 text-white text-xl font-light leading-relaxed">
            WMO operates a scalable and robust technology infrastructure consisting of proprietary
            as well as custom developed applications with a completely converged and secure global
            network. All of the critical components of our infrastructure have redundancy and are
            designed to seamlessly allow redirecting traffic from one location to another. WMO can
            integrate in its system client applications to align with client business process.
          </p>
        </div>
      }
      secondarySlot={<StaticImage 
        style={{
          zIndex: -100,       
        }}
        src="../assets/svg/infrastructure.svg"
        alt="Infrastructure"      
        layout='fullWidth'
        loading='eager'
        placeholder='office'
        />}  
    />
    {/* Stats */}
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 text-white lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}

    {/* Testimonials */}

    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8  text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    {/* Banner */}
    {/* <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold text-white">Ready to grow your business?</h3>
      <p className="mt-8 text-white text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8 text-white">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section> */}
  </Layout>
  )
}

export default Index;